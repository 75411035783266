import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NetworkStatus, useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAnalytics } from 'use-analytics';
import set from 'lodash/set';
import omit from 'lodash/omit';
import groupBy from 'lodash/groupBy';
import differenceBy from 'lodash/differenceBy';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';

import Box from '@mui/material/Box';

import Filters from './Filters';
import ItemSidebar from '../../components/ItemSidebar';
import Toolbar from './Toolbar';
import TableView from './Views/TableView';
import InsightView from './Views/InsightView';
import NoDataScreen from './NoDataScreen';

import { TableColumnsDialog } from '../../components/ItemsTable';

import DeleteItemsDialog from '../../components/DeleteItemsDialog';
import LoadingBanner from '../../components/LoadingBanner';

import useDefaultColumns from '../../hooks/table/defaultColumns';
import useColumns from '../../hooks/table/columns';
import useTable from '../../hooks/table';
import useSelectedItems from '../../components/ItemsTable/hooks/selected-items.hook';
import useItemsSorting from '../../components/ItemsTable/hooks/items-sorting.hook';
import useBatchActions from '../../components/ItemsTable/hooks/batch-actions.hook';
import useDocumentTitle from '../../hooks/document-title';
import useStateControl from './hooks/useStateControl';

import { GET_ITEMS } from './services';

import { componentNames, TRACK_SINGLE_ITEM, TRACK_VIEW_TYPE } from '../../analytics/constants';

import { openAppSnackbarNotification } from '../../services/snackbar-notifications/actions';

import isFiltersEmpty from './utils/isFiltersEmpty';
import refetchWithNewColumns from './utils/refetchWithNewColumns';
import getFiltersWithoutExtraData from './utils/getFiltersWithoutExtraData';
import { rolesIds } from '../../utils/roles';

import { COLOR_PRIMARY, COLOR_WHITE } from '../../styles';
import { routes } from '../../services/session/constants';
import { TABLE_VIEW } from './constants';

import useSavedViews from './hooks/useSavedViews';
import useTableColumnWidth from '../../hooks/useTableColumnWidth/useTableColumnWidth';

const pageStyles = {
  width: '100%',
  display: 'flex'
};

const containerStyles = {
  width: '100%',
  height: '100%',
  overflow: 'auto',
  boxSizing: 'border-box',
  borderLeft: '2px solid rgb(220, 219, 220)',
  display: 'flex',
  flexDirection: 'column'
};

const getAllFolderTables = (foldersById, id, tables = []) => {
  if(!Object.hasOwn(foldersById, id))
    return tables;

  const { childFolders, childTables } = foldersById[id];

  if(childTables.length) {
    tables.push(...childTables);
  }

  if(childFolders.length) {
    childFolders.forEach(({ id }) => getAllFolderTables(foldersById, id, tables));
  }

  return tables;
};

const createItemsInput = (searchParams, initialQueryInput, tablesData, foldersData) => {
  if(initialQueryInput)
    return initialQueryInput;

  const result = {};

  const selectedFolderParam = searchParams.get('filterFolder');
  const selectedTeamParam = searchParams.get('filterTeam');
  const selectedOwnerParam = searchParams.get('filterOwner');

  if(selectedFolderParam) {
    // select all tables in the given folder
    const foldersById = {};

    for(const folder of foldersData) {
      foldersById[folder.id] = folder;
    }

    const tables = getAllFolderTables(foldersById, selectedFolderParam);

    if(tables.length)
      set(result, 'filters.tableIds', tables.map(({ id }) => id));
  }

  if (selectedTeamParam) {
    set(result, 'filters.tableIds[0]', selectedTeamParam);
  }

  if (selectedOwnerParam) {
    set(result, 'filters.creatorIds[0]', selectedOwnerParam);
  }

  //Check all teams when clicking 'View my items'
  if (result.filters?.creatorIds?.length === 1 && !result.filters?.tableIds?.length){
    result.filters.tableIds = tablesData.map(table => table.id);
  }

  return result;
};

/**
 * @param {string} displayView
 * @param {object} queryInput
 * @param {object} queryInput.filters
 * @param {string} queryInput.search
 * @return {boolean}
 */
const isShowTable = (displayView, queryInput) => {
  if (displayView !== TABLE_VIEW)
    return true;

  return queryInput.filters?.tableIds || queryInput.search;
};

const AllItems = ({ loaderData }) => {
  const [searchParams] = useSearchParams();

  // save loader data into ref
  // because we remove the url search params after the initial render
  const initialView = useRef(loaderData?.view ?? null);
  const sharedView = useRef(loaderData?.sharedView ?? null);

  useDocumentTitle('Items');

  const { track } = useAnalytics();

  const navigate = useNavigate();

  const initialQueryInput = initialView.current?.queryInput ?? sharedView.current?.queryInput;
  const initialDashboardId = initialView.current?.dashboardId ||
    loaderData?.insightsData?.insight?.id ||
    sharedView.current?.dashboardId;

  const [activeFilters, setActiveFilters] = useState(() =>
    createItemsInput(searchParams, initialQueryInput, loaderData.tablesData, loaderData.foldersData).filters ?? {}
  );
  const [searchText, setSearchText] = useState(initialQueryInput?.search || '');
  const [tableColumnsDialogId, setTableColumnsDialogId] = useState(null);
  const [columnVisibilityOptions, setColumnVisibilityOptions] = useState([]);
  const [fetchMoreError, setFetchMoreError] = useState(null);
  const [filtersOptions, setFiltersOptions] = useState(null);

  // FIXME: a dirty hack to prevent params filter to sync
  // when the saved view is applied and filters are not being updated yet
  const [disableParamsFilterSync, setDisableParamsFilterSync] = useState(false);

  const { state, actions } = useStateControl({
    initialSavedView: initialView.current,
    initialDisplayView: initialDashboardId || TABLE_VIEW,
    initialDashboardStateId: initialView.current?.dashboardStateId || sharedView.current?.dashboardStateId
  });

  const selectedItems = useSelectedItems();
  const itemsSorting = useItemsSorting(initialQueryInput);

  const dispatch = useDispatch();

  const pageRef = useRef();
  const dashboardRef = useRef();
  const queryVariables = useRef({
    input: {
      sort: itemsSorting.state.param ? itemsSorting.state : null,
      ...createItemsInput(searchParams, initialQueryInput, loaderData.tablesData, loaderData.foldersData)
    },
    first: 100
  });
  const refetchOnError = useRef(true);

  useEffect(() => {
    if(loaderData.error) {
      dispatch(
        openAppSnackbarNotification({
          variant: 'ERROR',
          message: loaderData.error.message
        })
      );
    } else if (loaderData.dataErrors.length){
      loaderData.dataErrors.forEach((error) => {
        dispatch(
          openAppSnackbarNotification({
            variant: 'ERROR',
            message: error.message
          })
        );
      }
      );
    }
  }, [loaderData, dispatch]);

  useEffect(() => {
    if (searchParams.size){
      navigate(routes.ALL_ITEMS, { replace: true });
    }
  }, [navigate, searchParams]);

  const showTable = isShowTable(state.displayView, queryVariables.current.input);

  const {
    data,
    error,
    refetch,
    fetchMore,
    networkStatus,
    updateQuery
  } = useQuery(GET_ITEMS, {
    variables: {
      ...queryVariables.current,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    async onCompleted(data) {
      refetchOnError.current = true;

      const { tableHeaderFeatureIDs } = queryVariables.current.input;

      if(tableHeaderFeatureIDs?.length) {
        const inaccessibleColumns = data.tableItems.itemsTable.columns.filter(
          ({ notInCurrentFilterResult }) => notInCurrentFilterResult
        );
        const inaccessibleColumnIds = new Set(inaccessibleColumns.map(({ featureId }) => featureId));

        if(inaccessibleColumns.length) {
          const validFeatureIds = tableHeaderFeatureIDs.filter(id => !inaccessibleColumnIds.has(id));

          if(validFeatureIds.length){
            queryVariables.current.input.tableHeaderFeatureIDs = validFeatureIds;
          } else {
            delete queryVariables.current.input.tableHeaderFeatureIDs;
          }

          const columnTitles = inaccessibleColumns.map(({ title }) => title);

          dispatch(
            openAppSnackbarNotification({
              variant: 'ERROR',
              message: `${columnTitles.join(', ')} ${columnTitles.length === 1 ? 'was' : 'were'} not found and removed from the displayed view`
            })
          );

          await refetch({ ...queryVariables.current });
        }
      } else if(queryVariables.current.input.filters?.creatorIds) {
        const excludeIds = differenceBy(
          queryVariables.current.input.filters.creatorIds,
          data.tableItems.filterOptions.creators,
          (val) => {
            if(typeof val === 'string')
              return val;

            return val?.userId;
          }
        );

        if(excludeIds.length) {
          dispatch(
            openAppSnackbarNotification({
              variant: 'ERROR',
              message: 'Failed to fetch the data. You don\'t have access to some applied filter options of the "Owners" section.'
            })
          );

          delete queryVariables.current.input.filters;

          await refetch({ ...queryVariables.current });

          setActiveFilters({});
          actions.resetDashboardState();
        }
      }
    }
  });

  const refetchQuery = useCallback(async (vars) => {
    setFetchMoreError(null);

    return refetch({ ...vars });
  }, [refetch]);

  const onError = useCallback(async (error) => {
    dispatch(
      openAppSnackbarNotification({
        variant: 'ERROR',
        message: error.message
      })
    );

    if(!refetchOnError.current) return;

    delete queryVariables.current.input.filters;
    setActiveFilters({});

    refetchOnError.current = false;

    await refetch({ ...queryVariables.current, noFilters: !showTable });

    actions.resetDashboardState();
  }, [refetch, dispatch, showTable, actions]);

  // onError option of the query fires twice for unknown reason
  useEffect(() => {
    if(error)
      onError(error);
  }, [error, onError]);

  const { setIds } = selectedItems;

  useEffect(() => {
    if(data) {
      const ids = data
        .tableItems
        ?.itemsTable
        ?.edges
        .map(({ node }) => node.item.id) || [];

      setIds(ids);
    }
  }, [data, setIds]);

  const savedViewsProps = useSavedViews({
    queryId: data?.tableItems?.tableItemQueryId,
    displayView: state.displayView,
    insightsData: loaderData.insightsData,
    queryVariables: queryVariables.current,
    savedView: state.savedView,
    pageRef,
    dashboardRef,
    actions,

    searchText,
    setSearchText,
    setDisableParamsFilterSync,
    refetchQuery,
    setActiveFilters,
    itemsSorting
  });

  const handleToggleSidebar = useCallback((id, tab, source) => {
    actions.setActiveItem({
      id: id ?? null,
      tab: tab ?? null,
      source: source ?? null
    });
  }, [actions]);

  const tableData = useMemo(() => {
    const items = data
      ?.tableItems
      ?.itemsTable
      ?.edges
      .map(({ node }) => {
        return {
          ...node.item,
          values: node.values
        };
      }) ?? [];

    if(selectedItems.state.displaySelected)
      return items.filter(
        ({ id }) => selectedItems.selectedItemIds.includes(id)
      );

    return items;
  }, [
    data,
    selectedItems.selectedItemIds,
    selectedItems.state.displaySelected
  ]);

  const handleSearch = useCallback(async (text) => {
    const search = text.trim();

    setSearchText(text);

    if(search) {
      queryVariables.current.input.search = search;
      itemsSorting.sortItems({ param: null, order: null });
      delete queryVariables.current.input.sort;
    } else {
      if(!queryVariables.current.input.sort) {
        queryVariables.current.input.sort = itemsSorting.sortItems({
          param: 'DATE_UPDATED',
          order: 'DESC'
        });
      }
      delete queryVariables.current.input.search;
    }

    await refetchQuery(queryVariables.current);

    track(TRACK_SINGLE_ITEM.search, {
      component: componentNames.ITEM_SEARCH,
      additional_info: {
        search_string: search
      }
    });
  }, [itemsSorting, refetchQuery, track]);

  const handleSort = useCallback(async (sort) => {
    queryVariables.current.input.sort = itemsSorting.sortItems(sort);

    pageRef.current.scrollTo(pageRef.current.scrollLeft, 0);

    return refetchQuery(queryVariables.current);
  }, [refetchQuery, itemsSorting]);

  const batchActions = useBatchActions({
    items: tableData,
    selectedItemIds: selectedItems.selectedItemIds
  });

  const handleDelete = useCallback((id) => {
    batchActions.deleteItems(id);
  }, [batchActions]);

  const clearCache = useCallback((idsToFilter, amountToRemove, tablesToUpdate) => {
    updateQuery(cache => ({
      ...cache,
      tableItems: {
        ...cache.tableItems,
        filterOptions: {
          ...cache.tableItems.filterOptions,
          tables: cache.tableItems.filterOptions.tables.map(table => {
            if (tablesToUpdate.has(table.id))
              return {
                ...table,
                number: table.number - 1
              };

            return table;
          })
        },
        itemsTable: {
          ...cache.tableItems.itemsTable,
          filterInfo: {
            ...cache.tableItems.itemsTable.filterInfo,
            totalNumberOfFilteredItems: cache.tableItems.itemsTable.filterInfo.totalNumberOfFilteredItems - amountToRemove
          },
          edges: cache.tableItems.itemsTable.edges.filter(
            ({ node }) => !idsToFilter.includes(node.item.id)
          )
        }
      }
    })
    );
  }, [updateQuery]);

  const handleDeleteItemsSubmit = useCallback(async (ids, teamsToUpdate) => {
    clearCache(ids, ids.length, teamsToUpdate);

    batchActions.reset();
  }, [batchActions, clearCache]);

  const { toggleDisplaySelected } = selectedItems;

  const handleToggleDisplaySelected = useCallback(val => {
    const value = typeof val === 'boolean' ? val : null;

    toggleDisplaySelected(value);
  }, [toggleDisplaySelected]);

  const handleColumnRemove = useCallback(async ({ id: columnId, table }) => {
    await refetchWithNewColumns({
      columnToDelete: columnId,
      table,
      queryVariables,
      refetchQuery,
    });

    pageRef.current?.scrollTo(0, 0);
  }, [refetchQuery]);

  const batchItemActions = useMemo(() => {
    return {
      onDelete: handleDelete,
    };
  }, [handleDelete]);

  const tablesById = useMemo(() => {
    const result = {};

    for(const table of loaderData.tablesData ?? []) {
      result[table.id] = table;
    }

    return result;
  }, [loaderData.tablesData]);

  const { titleWidth, onUpdateTitleWidth } = useTableColumnWidth();

  const defaultColumns = useDefaultColumns();

  const columns = useColumns({
    featuresData: data?.tableItems?.itemsTable?.columns,
    defaultColumns,
    sortable: !selectedItems.state.displaySelected
  });

  const table = useTable({
    columns,
    data: tableData,
    customState: {
      searchText,
      selectedItems,
      batchItemActions,
      titleWidth,
      onUpdateTitleWidth,
      onColumnRemove: handleColumnRemove,
      itemsSorting: {
        state: itemsSorting.state,
        onSort: handleSort
      },
      handleToggleSidebar
    }
  });

  const handleTableColumnsDialogOpen = useCallback(() => {
    const result = [];
    const itemInfoColumn = table.getColumn('Item info');

    result.push({
      id: 'Item info',
      title: 'Item info',
      visible: itemInfoColumn.isVisible(),
      columns: itemInfoColumn.columns.map(column => ({
        id: column.id,
        title: column.title,
        visible: column.isVisible()
      }))
    });

    const columnOptions = data
      ?.tableItems
      .columnOptions ?? [];

    const groupedColumnOptions = groupBy(columnOptions, 'protocolTitle');

    for(const [title, features] of Object.entries(groupedColumnOptions)) {
      result.push({
        id: title,
        title,
        visible: Boolean(table.getColumn(title)),
        columns: features.map(({ featureId, title, notInCurrentFilterResult }) => ({
          id: featureId,
          title,
          visible: Boolean(table.getColumn(featureId)),
          notActive: notInCurrentFilterResult
        }))
      });
    }

    setColumnVisibilityOptions(result);
    setTableColumnsDialogId(window.crypto.randomUUID());
  }, [table, data]);

  const handleTableColumnsDialogClose = useCallback(() => {
    setColumnVisibilityOptions([]);
    setTableColumnsDialogId(null);
  }, []);

  const handleTableColumnsDialogSubmit = useCallback(async (columnGroups) => {
    await refetchWithNewColumns({
      items: columnGroups,
      table,
      queryVariables,
      refetchQuery,
    });

    pageRef.current?.scrollTo(0, 0);
    handleTableColumnsDialogClose();
  }, [table, refetchQuery, handleTableColumnsDialogClose]);

  const loadingMore = networkStatus === NetworkStatus.fetchMore;
  const displayLoadingBanner = networkStatus === NetworkStatus.loading ||
      networkStatus === NetworkStatus.setVariables ||
    networkStatus === NetworkStatus.refetch;

  const totalItems = data
    ?.tableItems
    ?.itemsTable
    ?.filterInfo
    .totalNumberOfFilteredItems;

  const handleFilterQuery = useCallback(async (dataToFilter) => {
    const filteredFeatures = getFiltersWithoutExtraData(dataToFilter, loaderData.tablesData, itemsSorting);
    queryVariables.current.input = filteredFeatures;

    setActiveFilters(filteredFeatures.filters);

    try {
      await refetchQuery(queryVariables.current);
    } catch (e) {
      console.error(e);
    }

    pageRef.current.scrollTo(0, 0);
  }, [itemsSorting, loaderData.tablesData, refetchQuery]);

  const handleFilterUpdate = useCallback((type, data) => {
    let filters = {};

    if(type && !data) {
      filters = omit(activeFilters, type);
    } else if(data) {
      filters = {
        ...activeFilters,
        [type]: data
      };
    }

    if (isEmpty(omit(filters, 'featureSliceByList')))
      filters = {};

    const dataToFilter = {
      ...queryVariables.current,
      input: {
        ...queryVariables.current.input,
        filters
      }
    };

    handleFilterQuery(dataToFilter);
  }, [handleFilterQuery, activeFilters]);

  const handleToolbarFilters = useCallback((newFilters) => {
    let filters = {
      tableIds: activeFilters.tableIds,
      ...omitBy(newFilters, isEmpty)
    };

    if (!newFilters){
      filters = omit(filters, ['creatorIds', 'createdTimestamp', 'featureSliceByList']);
    }

    const dataToFilter = {
      ...queryVariables.current,
      input: {
        ...queryVariables.current.input,
        filters
      }
    };

    handleFilterQuery(dataToFilter);
  }, [activeFilters, handleFilterQuery]);

  const handleSingleItemRemove = useCallback((id, tableId) => {
    handleToggleSidebar();

    clearCache([id], 1, new Set([tableId]));
  }, [clearCache, handleToggleSidebar]);

  const handleClear = useCallback(() => {
    selectedItems.checkAllChange(false);
  }, [selectedItems]);

  const currentInsightData = useMemo(() => {
    return loaderData.insightsData?.insights.find(insight => insight.id === state.displayView);
  }, [state.displayView, loaderData.insightsData?.insights]);

  const handleSelectDisplayView = useCallback((value) => {
    actions.setDisplayView(value);

    let type = 'table';

    if(value !== TABLE_VIEW)
      type = loaderData.insightsData?.insights.find(insight => insight.id === value)
        .title.toLowerCase();

    track(TRACK_VIEW_TYPE.change, {
      component: componentNames.DATA_VIEW,
      additional_info: {
        ai_view_type: type
      }
    });
  }, [actions, loaderData.insightsData?.insights, track]);

  useEffect(() => {
    if(loaderData.insightsData?.error){
      dispatch(openAppSnackbarNotification({
        message: 'You don’t have permission to view this dashboard. To request access please contact support.',
        variant: 'ERROR'
      }));

      actions.setDisplayView(TABLE_VIEW);
    }
  }, [dispatch, loaderData.insightsData?.error, actions]);

  const disableViewTools = useMemo(() => {
    return !showTable || isEmpty(activeFilters);
  }, [activeFilters, showTable]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.ready && !isEqual(data?.tableItems?.filterOptions, filtersOptions))
      setFiltersOptions(data?.tableItems?.filterOptions);
  }, [data?.tableItems?.filterOptions, filtersOptions, loaderData, networkStatus]);

  const mergedFilters = useMemo(() => {
    const tableFilters = filtersOptions?.tables?.map(table => {
      let additionalData = tablesById[table.id];

      if (table.isFolder){
        additionalData = loaderData?.foldersData.find(folder => folder.id === table.id);
      }

      return {
        ...table,
        description: additionalData?.description,
        hash: additionalData?.hash,
        viewerMaxRole: additionalData?.viewerMaxRole || rolesIds.NO_ACCESS
      };
    });

    return {
      ...filtersOptions,
      tables: tableFilters
    };
  }, [filtersOptions, loaderData?.foldersData, tablesById]);

  const handleFilterDelete = useCallback((ids) => {
    if (!activeFilters.tableIds)
      return;

    setActiveFilters(state => ({
      ...state,
      tableIds: state.tableIds.filter(teamId => {
        if (typeof ids === 'string') {
          return teamId !== ids;
        }

        return !ids.includes(teamId);
      }),
    }));

    const filters = queryVariables.current.input.filters;

    const filteredTableIds = filters.tableIds
      .filter(teamId => {
        if (typeof ids === 'string') {
          return teamId !== ids;
        }

        return !ids.includes(teamId);
      });

    if (filteredTableIds.length){
      queryVariables.current.input.filters.tableIds = filteredTableIds;
    } else queryVariables.current.input.filters = omit(filters, 'tableIds');
  }, [activeFilters.tableIds]);

  if(!table.headerGroups?.length)
    return null;

  const displayDashboard = state.displayView !== TABLE_VIEW;

  return (
    <Box sx={pageStyles}>
      <Filters
        data={mergedFilters}
        activeFilters={activeFilters}
        disableSaving={disableViewTools}
        //boolean values
        hideFilters={isFiltersEmpty({ queryInput: queryVariables.current.input })}
        disableParamsFilterSync={disableParamsFilterSync}
        disabledFilters={!activeFilters.tableIds?.length}
        //callbacks
        onFilterUpdate={handleFilterUpdate}
        onFilterDelete={handleFilterDelete}
        savedViewsProps={savedViewsProps}
      />

      <Box
        sx={containerStyles}
        ref={pageRef}
      >
        <Toolbar
          selectedIds={selectedItems.selectedItemIds}
          totalItems={totalItems}
          initialView={initialView.current}
          queryId={data?.tableItems?.tableItemQueryId}
          displayView={state.displayView}
          insightsData={loaderData.insightsData}
          dashboardRef={dashboardRef}
          dashboardData={currentInsightData}
          displaySelected={selectedItems.state.displaySelected}
          tablesById={tablesById}
          disableViewTools={disableViewTools}
          onToggleDisplaySelected={handleToggleDisplaySelected}
          onDelete={handleDelete}
          onSelectDisplayView={handleSelectDisplayView}
          onClear={handleClear}
          onSearch={handleSearch}
          onOpenSelectParams={handleTableColumnsDialogOpen}
          onUpdateSavedView={actions.updateSavedView}
          filtersData={mergedFilters}
          activeFiltersData={activeFilters}
          onFiltersSubmit={handleToolbarFilters}
          savedViewsProps={savedViewsProps}
          queryVariables={queryVariables.current}
          filtersDisabled={!activeFilters.tableIds?.length}
          removeFilters={isFiltersEmpty({ queryInput: queryVariables.current.input })}
          disableParamsFilterSync={disableParamsFilterSync}
          onSingleFilterUpdate={handleFilterUpdate}
          handleToggleSidebar={handleToggleSidebar}
        />

        {displayDashboard ?
          <InsightView
            key={state.dashboardKey}
            ref={dashboardRef}
            tableItemQueryId={data?.tableItems?.tableItemQueryId}
            currentInsightData={currentInsightData}
            dashboardStateId={state.dashboardStateId}
            onRemoveItem={handleSingleItemRemove}
          /> :
          null
        }

        {state.displayView === TABLE_VIEW ?
          showTable ?
            <TableView
              onSettingsClick={handleTableColumnsDialogOpen}
              table={table}
              ref={pageRef}
              loadingMore={loadingMore}
              selectedItems={selectedItems}
              pageInfo={data?.tableItems?.itemsTable?.pageInfo}
              fetchMoreError={fetchMoreError}
              onFetchMoreError={setFetchMoreError}
              fetchMore={fetchMore}
              chosenItemId={state.activeItem?.id}
            /> :
            <NoDataScreen /> :
          null
        }

        {displayLoadingBanner ?
          <LoadingBanner
            overlay
            spinner
            bcgcolor={COLOR_WHITE}
            color={COLOR_PRIMARY}
          /> :
          null
        }

        <TableColumnsDialog
          key={tableColumnsDialogId}
          open={Boolean(tableColumnsDialogId)}
          loading={displayLoadingBanner}
          options={columnVisibilityOptions}
          onClose={handleTableColumnsDialogClose}
          onSubmit={handleTableColumnsDialogSubmit}
        />

        {batchActions.state.actionType === batchActions.actionTypes.DELETE &&
          <DeleteItemsDialog
            items={batchActions.state.items}
            onClose={batchActions.reset}
            onSuccess={handleDeleteItemsSubmit}
          />
        }
      </Box>

      <ItemSidebar
        search={queryVariables.current.input.search}
        onClose={handleToggleSidebar}
        itemId={state.activeItem?.id}
        defaultTab={state.activeItem?.tab}
        onRemove={handleSingleItemRemove}
        clickSource={state.activeItem?.source}
      />
    </Box>
  );
};

AllItems.propTypes = {
  loaderData: PropTypes.object.isRequired,
};

export default AllItems;
